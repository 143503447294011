
import { isDesktop } from "~/utils/device";

export default {
    data() {
        return {
            isClosed: false,
            isVisible: false,
            isExpanded: false,
            windowWidth: typeof window !== "undefined" ? window.innerWidth : 0,
        };
    },
    mounted() {
        console.log("section176 mounted", this.$attrs);
        this.windowWidth = window.innerWidth;

        if (process.client) {
            window.addEventListener("resize", this.updateWindowWidth);
            window.addEventListener("scroll", this.scrollToTopCta);
            this.scrollToTopCta(); // run initially to set visibility based on scroll
        }
    },
    beforeUnmount() {
        if (process.client) {
            window.removeEventListener("resize", this.updateWindowWidth);
            window.removeEventListener("scroll", this.scrollToTopCta);
        }
    },
    methods: {
        toggleMainContainer() {
            if (this.windowWidth <= 767) {
                this.isExpanded = !this.isExpanded;
            }
        },
        closeWidget() {
            this.isClosed = true;
        },
        scrollToTopCta() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            this.isVisible = scrollTop > 500;
        },
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
    },
};
